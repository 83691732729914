iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}

:root {
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

body.ready .body-wrapper {
  opacity: 1;
}

a {
  cursor: pointer;
  text-decoration: none;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
}
a.color-link {
  text-decoration: underline;
  font-weight: bold;
}
a.color-link:hover {
  text-decoration: none;
}

input {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  outline: 0;
  padding: 5px 12px;
  margin-right: 5px;
}

.btn {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
}
.btn:hover {
  box-shadow: 0 2px 5px #9a9696;
}
.btn.btn-blue {
  background-image: linear-gradient(#227df0, #24b9ff);
}
.btn.btn-red {
  background-image: linear-gradient(#fd2122, #b51d21);
}

.container {
  width: 1000px;
  margin: auto;
}

.hide {
  display: none !important;
}

/** ****************** Header ****************** */
.top-header {
  background-color: #c5c5c5;
  color: #2a2a2a;
  padding: 3px;
}
.top-header .container {
  display: flex;
  align-items: center;
}
.top-header .nowtime {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.top-header .links-wrapper {
  margin-left: auto;
}
.top-header .links-wrapper a {
  color: #2a2a2a;
  font-size: 0.9rem;
}
.top-header .links-wrapper a:hover {
  color: #0278f0;
}
.top-header .links-wrapper span {
  padding: 0 8px;
}

.form-header {
  background-color: #fff;
}
.form-header .container {
  display: flex;
}
.form-header .logo-wrapper {
  display: flex;
  align-items: center;
}
.form-header .logo-wrapper img {
  margin-right: 10px;
  max-height: 65px;
  padding: 5px 0;
}
.form-header .form-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 12px 0;
}
.form-header .form-wrapper form {
  display: flex;
  align-items: center;
}
.form-header .form-wrapper form a,
.form-header .form-wrapper form button {
  margin-left: 5px;
}
.form-header .form-wrapper form .btn {
  font-size: 0.9rem;
  height: 25px;
  min-width: 68px;
}
.form-header .form-wrapper form .input-wrapper {
  position: relative;
  max-width: 165px;
  margin-right: 6px;
}
.form-header .form-wrapper form .input-wrapper input {
  background-color: #d6d6d6;
  width: 100%;
  margin-right: 0;
}
.form-header .form-wrapper form .input-wrapper input::placeholder {
  color: #fff;
}
.form-header .form-wrapper .forgot-pwd {
  color: #c52325;
  white-space: nowrap;
}
.form-header .form-wrapper .verify-code {
  height: 22px;
  width: 55px;
  position: absolute;
  right: 9px;
  top: 2px;
}
.form-header .form-wrapper .header-links {
  margin-left: auto;
  display: flex;
}
.form-header .form-wrapper .header-links.hide {
  display: none;
}
.form-header .form-wrapper .header-links ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
}
.form-header .form-wrapper .header-links ul li {
  list-style: none;
}
.form-header .form-wrapper .header-links ul li:not(:last-child) {
  margin-right: 20px;
}
.form-header .form-wrapper .header-links ul li a {
  color: #2a2a2a;
  white-space: nowrap;
}
.form-header .form-wrapper .header-links ul li a:hover {
  color: #0278f0;
}
.form-header .form-wrapper .header-links ul li span {
  color: #0278f0;
}

.nav-header {
  background-color: #0278f0;
}
.nav-header .nav-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.nav-header .nav-row .btn-menu {
  position: relative;
  z-index: 0;
}
.nav-header .nav-row .btn-menu:before {
  background-color: #04b8ff;
  content: "";
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transform: skewX(0);
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}
.nav-header .nav-row .btn-menu.active:before, .nav-header .nav-row .btn-menu:hover:before {
  opacity: 1;
  transform: skewX(21deg);
}
.nav-header .nav-row .btn-menu a {
  color: #fff;
  display: block;
  padding: 13px 20px;
}

/** ****************** Footer ****************** */
.footer .payment {
  background-color: #f0f0f0;
  color: #187bba;
  font-weight: bold;
  padding: 20px 0;
  text-align: center;
}
.footer .copyright {
  background-image: linear-gradient(#376afd, #0437b6);
  color: #fff;
  font-size: 0.85rem;
  padding: 20px;
}
.footer .copyright .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .copyright .copyright-right {
  text-align: right;
}
.footer .copyright .copyright-right img {
  padding-top: 5px;
}

/** ****************** Sidebar ****************** */
.right-sidebar {
  position: fixed;
  right: 20px;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  width: 120px;
  z-index: 4;
}
.right-sidebar .cs-wrapper {
  position: relative;
  margin-bottom: -50px;
}
.right-sidebar .cs-wrapper .cs-lady {
  margin-bottom: -17px;
  width: 125px;
  z-index: -1;
}
.right-sidebar .cs-wrapper .cs-24hr {
  width: 100%;
}
.right-sidebar .cs-wrapper .cs-text {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: -5px;
}
.right-sidebar .cs-wrapper .cs-link {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.right-sidebar .sidebar-container {
  background: url("../img/sidebar-bg.png") top center/cover no-repeat #2d75d0;
  border: 1px solid #635ae2;
  border-radius: 6px;
  color: #fff;
  padding: 53px 10px 10px;
}
.right-sidebar .sidebar-container .welcome {
  color: #fff653;
  font-size: 1.3rem;
  font-weight: bold;
}
.right-sidebar .sidebar-container .qr-wrapper {
  font-size: 0.9rem;
  margin: 6px 0 10px;
}
.right-sidebar .sidebar-container .sidebar-item {
  background-image: linear-gradient(#fff471, #ebc940, #c19321);
  border-radius: 20px;
  color: #331400;
  display: block;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 6px;
  padding: 5px;
  position: relative;
}
.right-sidebar .sidebar-container .sidebar-item:hover {
  color: #000;
}
.right-sidebar .sidebar-container .sidebar-item:hover .sidebar-desc {
  opacity: 1;
  transform: translate(0, -50%);
  visibility: visible;
}
.right-sidebar .sidebar-container .sidebar-desc {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  color: #fff;
  display: block;
  min-width: 60px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  text-decoration: none;
  position: absolute;
  right: 120px;
  top: 50%;
  transform: translate(-30%, -50%);
  padding: 5px;
  text-align: center;
  font-size: 0.8rem;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
}
.right-sidebar .sidebar-container .sidebar-desc:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(0, 0, 0, 0.7);
}
.right-sidebar .sidebar-container .sidebar-desc div {
  word-break: break-all;
}
.right-sidebar .sidebar-container .sidebar-desc img,
.right-sidebar .sidebar-container .sidebar-desc .qr-code {
  display: block;
  max-width: 130px;
}
.right-sidebar .sidebar-close {
  color: #2a2a2a;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
}
.right-sidebar .sidebar-close:hover {
  color: #000;
}

/** ****************** Marquee ****************** */
.notice-row {
  background-color: #346cfd;
  color: #fff;
}
.notice-row .container {
  display: flex;
  align-items: center;
}
.notice-row .notice-desc {
  display: flex;
  align-items: center;
  padding-right: 10px;
  white-space: nowrap;
}
.notice-row .marquee-box {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  line-height: 38px;
  height: 38px;
}
.notice-row .marquee-box ul {
  overflow: hidden;
  zoom: 1;
  margin: 0;
}
.notice-row .marquee-box ul li {
  margin-right: 20px;
  float: left;
  height: 40px;
  line-height: 40px;
  text-align: left;
  display: inline;
  width: auto !important;
}
.notice-row .marquee-box ul li a {
  color: #fff;
}

/** ****************** Index ****************** */
.homepage .hotgame {
  background-color: #f0f0f0;
  display: flex;
  padding: 30px 0;
}
.homepage .hotgame .hotgame-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homepage .hotgame .hotgame-wrapper .hotgame-box {
  position: relative;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
}
.homepage .hotgame .hotgame-wrapper .hotgame-box:hover {
  transform: scale(1.1);
}
.homepage .hotgame .hotgame-wrapper .hotgame-box img {
  max-width: 100px;
}
.homepage .hotgame .hotgame-wrapper .hotgame-box a {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.homepage .hotgame .hotgame-wrapper .hotgame-box a.hide {
  display: none !important;
}
.homepage .about {
  padding: 40px 0;
}
.homepage .about .container {
  display: flex;
}
.homepage .about .about-col {
  color: #00369b;
  flex: 1;
  padding: 0 20px;
  position: relative;
  text-align: center;
}
.homepage .about .about-col:before {
  background-color: #d9d9d9;
  content: "";
  height: calc(100% - 45px);
  width: 1px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.homepage .about .about-col:last-child:before {
  display: none;
}
.homepage .about .about-col .title {
  color: #207cba;
  height: 45px;
  font-size: 1.2rem;
  font-weight: bold;
}
.homepage .about .about-col.benefit strong {
  color: #207cba;
  font-size: 1.5rem;
}
.homepage .about .about-col.benefit .flex-wrapper {
  color: #000;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  font-size: 0.9rem;
  margin: 0 auto;
  max-width: 215px;
}
.homepage .about .about-col.benefit .progress {
  background-color: #d9d9d9;
  border-radius: 20px;
  height: 8px;
  width: 100%;
  margin: 5px auto 20px;
  max-width: 180px;
  overflow: hidden;
}
.homepage .about .about-col.benefit .progress .progress-bar {
  background-image: linear-gradient(to right, #48276d, #384188, #4770af);
  border-radius: 20px;
  height: 100%;
  width: 0;
}
.homepage .about .about-col.aboutus p {
  color: #000;
  line-height: 1.2rem;
  text-align: left;
}
.homepage .about .about-col.mobile .download-qr {
  background: url("../img/about-qrbg.png") top center/cover no-repeat;
  display: inline-block;
  margin-top: 20px;
  padding: 16px 10px 23px;
}

.banner {
  position: relative;
}
.banner .swiper-container {
  height: 500px;
  overflow: hidden;
}
.banner .swiper-container .swiper-wrapper {
  height: 100%;
}
.banner .swiper-container .swiper-wrapper .swiper-slide {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
}
.banner .swiper-container .swiper-wrapper .swiper-slide .img-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(10px) grayscale(50%);
  z-index: 0;
}
.banner .swiper-container .swiper-wrapper .swiper-slide img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.banner .swiper-container .swiper-pagination-bullets {
  bottom: 10px;
}

/** ****************** Register / Edit & Forgot Pwd ****************** */
.register {
  min-height: calc(100vh - 135px - 38px - 211px);
  padding: 30px 0;
}
.register .welcome {
  color: #0278f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 20px;
}
.register .step {
  background-color: #4897ed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 50px;
}
.register .step .step-box {
  color: #fdec75;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.register .step .step-box:not(:last-child) {
  padding-right: 55px;
}
.register .step .step-box:not(:last-child):after {
  content: "";
  display: inline-block;
  color: #fff;
  width: 18px;
  height: 18px;
  border-width: 1px 1px 0 0;
  border-style: solid;
  transform: rotate(45deg);
  position: absolute;
  right: 22px;
  z-index: 1;
}
.register .step .step-box.active {
  color: #fff;
}
.register .step .step-box .large {
  font-family: "Lato", sans-serif;
  font-size: 2.6rem;
  font-weight: 100;
  margin-right: 5px;
}
.register .step .step-box .desc {
  padding-top: 15px;
}
.register .register-box {
  padding: 50px;
}
.register .register-box .form .form-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.register .register-box .form .form-row .text {
  flex-basis: 10%;
  padding-right: 15px;
  text-align: right;
}
.register .register-box .form .form-row .inp {
  flex-basis: 35%;
  position: relative;
}
.register .register-box .form .form-row .inp input {
  color: #000;
  height: 30px;
  width: 100%;
}
.register .register-box .form .form-row .inp .verify-code {
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
}
.register .register-box .form .form-row .tip {
  color: #eb4539;
  flex-basis: 55%;
  padding-left: 20px;
  text-align: left;
}
.register .register-box .form .form-row .btn {
  width: 100px;
}

/** ****************** Faqs ****************** */
.faq {
  min-height: calc(100vh - 135px - 38px - 211px);
}
.faq .subpage-banner {
  background: url("../img/faq-bg.png") center/cover no-repeat #4dace2;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 200px;
}
.faq .subpage-banner .en {
  color: #3380ff;
  font-family: "Lato", sans-serif;
  font-size: 3.2rem;
}
.faq .subpage-banner .zh {
  color: #00319e;
}
.faq .subpage-banner .zh .large {
  font-size: 2.2rem;
  font-weight: bold;
  padding-right: 5px;
}
.faq .faq-wrapper .faq-menu {
  border-bottom: 1px solid #c8c8c8;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px 0 15px;
}
.faq .faq-wrapper .faq-menu .menu-item {
  color: #353535;
  cursor: pointer;
  text-align: center;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
}
.faq .faq-wrapper .faq-menu .menu-item:hover {
  color: #0278f0;
}
.faq .faq-wrapper .faq-menu .menu-item:hover img {
  transform: scale(1.1);
}
.faq .faq-wrapper .faq-menu .menu-item.active {
  color: #000;
}
.faq .faq-wrapper .faq-menu .menu-item img {
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 80px;
}
.faq .faq-wrapper .faq-content {
  padding: 20px;
}
.faq .faq-wrapper .faq-content a {
  color: #0278f0;
}
.faq .faq-wrapper .faq-content a:hover {
  color: #346cfd;
}
.faq .faq-wrapper .faq-content h2 {
  color: #003d9b;
  text-align: center;
}
.faq .faq-wrapper .faq-content h4 {
  margin-bottom: 8px;
}
.faq .faq-wrapper .faq-content p {
  margin-top: 0;
  margin-bottom: 5px;
}

/** ****************** Aff ****************** */
.aff {
  min-height: calc(100vh - 135px - 211px);
}
.aff .subpage-banner {
  background: url("../img/aff-bg.png") center/cover no-repeat #4dace2;
  color: #0046a0;
  display: flex;
  align-items: center;
  height: 200px;
}
.aff .subpage-banner .welcome {
  font-size: 1.8rem;
}
.aff .subpage-banner .large {
  color: #00439f;
  font-size: 2.2rem;
  font-weight: bold;
  padding-right: 5px;
  text-shadow: 2px 2px 3px #fff;
}
.aff .subpage-banner .en {
  color: #50affe;
  font-family: "Lato", sans-serif;
  font-size: 3.2rem;
}
.aff .subpage-banner .slogan {
  padding-left: 50px;
}
.aff .aff-wrapper {
  padding: 20px;
}
.aff .aff-wrapper .container {
  display: flex;
}
.aff .aff-wrapper .aff-menu {
  flex-basis: 15%;
  margin-right: 4px;
}
.aff .aff-wrapper .aff-menu .menu-item {
  background-color: #d0d0d0;
  border: 0;
  color: #000;
  cursor: pointer;
  display: block;
  margin-bottom: 4px;
  padding: 10px;
  text-align: center;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}
.aff .aff-wrapper .aff-menu .menu-item:hover, .aff .aff-wrapper .aff-menu .menu-item.active {
  background-color: #0591f8;
  color: #fff;
}
.aff .aff-wrapper .aff-content-wrapper {
  background-color: #0591f8;
  color: #fff;
  flex-basis: 85%;
  padding: 0 20px 20px;
}
.aff .aff-wrapper .aff-content-wrapper h4 {
  margin-bottom: 8px;
}
.aff .aff-wrapper .aff-content-wrapper p {
  margin-top: 5px;
}
.aff .aff-wrapper .aff-content-wrapper ol {
  padding-left: 20px;
  margin: 0;
}

/** ****************** App ****************** */
.app {
  background: url("../img/app-bg.png") center/cover no-repeat #a3e6ff;
  color: #fff;
  min-height: calc(100vh - 135px - 38px - 211px);
  padding: 20px 0;
}
.app .title {
  color: #0278f0;
  font-size: 3.2rem;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;
}
.app .subtitle {
  color: #0278f0;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-align: center;
}
.app .slogan {
  background: url("../img/app-slogan.png") center/contain no-repeat;
  font-size: 1.6rem;
  height: 80px;
  width: 500px;
  margin: 0 auto;
  padding-top: 15px;
  text-align: center;
}
.app .content {
  margin-top: 30px;
}
.app .content div {
  position: relative;
}
.app .content img {
  max-width: 400px;
  width: 100%;
}
.app .content img.logo1 {
  height: 20px;
  max-width: 70px;
  width: auto;
  position: absolute;
  right: 313px;
  top: 4px;
}
.app .content img.logo2 {
  height: 16px;
  max-width: 53px;
  width: auto;
  position: absolute;
  left: 340px;
  top: 85px;
}
.app .flex-wrapper {
  display: flex;
  align-items: center;
}
.app .flex-wrapper > div {
  flex: 1;
  text-align: center;
}

/** ****************** Promo ****************** */
.promo {
  min-height: calc(100vh - 135px - 38px - 211px);
}
.promo .subpage-banner {
  background: url("../img/promo-bg.png") center/cover no-repeat #112671;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 200px;
}
.promo .subpage-banner .en {
  font-family: "Lato", sans-serif;
  font-size: 3.5rem;
}
.promo .subpage-banner .zh {
  font-size: 1.5rem;
  text-shadow: 2px 2px 3px #1f1f1f;
}
.promo .subpage-banner .zh .large {
  color: #c79f30;
  font-size: 2.2rem;
  padding: 0 3px;
}
.promo .promo-wrapper {
  padding: 20px 0;
}
.promo .promo-wrapper .promo-box {
  margin-bottom: 30px;
}
.promo .promo-wrapper .promo-box .sub-content-item-content {
  background-color: #f3f3f3;
  margin-top: 8px;
  padding: 15px 20px;
  display: none;
}
.promo .promo-wrapper .promo-box img {
  max-width: 100%;
}

.cooperation-list {
  display: flex;
}
.cooperation-list > div {
  text-align: left;
}
.cooperation-list .cooperation-title {
  flex-basis: 150px;
  font-weight: bold;
}
.cooperation-list .cooperation-title:after {
  content: ":";
}
.cooperation-list .cooperation-qrcode {
  width: 150px;
  background: white;
}