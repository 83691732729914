@import "./../../common/styles";

$colorPrimary: #0278f0;
$btnGradientBlue: linear-gradient(#227df0, #24b9ff);
$btnGradientRed: linear-gradient(#fd2122, #b51d21);
$materialCubicBezier: cubic-bezier(0.4, 0, 0.2, 1);
// minus header, marquee, footer
$contentHeight: calc(100vh - 135px - 38px - 211px);

:root {
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}
body.ready .body-wrapper {
  opacity: 1;
}

a {
  cursor: pointer;
  text-decoration: none;
  transition: all 400ms $materialCubicBezier;

  &.color-link {
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
}

input {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  outline: 0;
  padding: 5px 12px;
  margin-right: 5px;
}

.btn {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: all 400ms $materialCubicBezier;
  &:hover {
    box-shadow: 0 2px 5px #9a9696;
  }

  &.btn-blue {
    background-image: $btnGradientBlue;
  }
  &.btn-red {
    background-image: $btnGradientRed;
  }
}

.container {
  width: 1000px;
  margin: auto;
}

.hide {
  display: none !important;
}

/** ****************** Header ****************** */
.top-header {
  background-color: #c5c5c5;
  color: #2a2a2a;
  padding: 3px;

  .container {
    display: flex;
    align-items: center;
  }

  .nowtime {
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  .links-wrapper {
    margin-left: auto;
    a {
      color: #2a2a2a;
      font-size: 0.9rem;
      &:hover {
        color: $colorPrimary;
      }
    }

    span {
      padding: 0 8px;
    }
  }
}

.form-header {
  background-color: #fff;

  .container {
    display: flex;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      max-height: 65px;
      padding: 5px 0;
    }
  }

  .form-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 12px 0;

    form {
      display: flex;
      align-items: center;

      a,
      button {
        margin-left: 5px;
      }

      .btn {
        font-size: 0.9rem;
        height: 25px;
        min-width: 68px;
      }

      .input-wrapper {
        position: relative;
        max-width: 165px;
        margin-right: 6px;
        input {
          background-color: #d6d6d6;
          width: 100%;
          margin-right: 0;
          &::placeholder {
            color: #fff;
          }
        }
      }
    }

    .forgot-pwd {
      color: #c52325;
      white-space: nowrap;
    }

    .verify-code {
      height: 22px;
      width: 55px;
      position: absolute;
      right: 9px;
      top: 2px;
    }

    .header-links {
      margin-left: auto;
      display: flex;

      &.hide {
        display: none;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3px 0;

        li {
          list-style: none;
          &:not(:last-child) {
            margin-right: 20px;
          }

          a {
            color: #2a2a2a;
            white-space: nowrap;
            &:hover {
              color: $colorPrimary;
            }
          }
          span {
            color: $colorPrimary;
          }
        }
      }
    }
  }
}

.nav-header {
  background-color: $colorPrimary;

  .nav-row {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .btn-menu {
      position: relative;
      z-index: 0;

      &:before {
        background-color: #04b8ff;
        content: "";
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transform: skewX(0);
        transition: all 400ms $materialCubicBezier;
        z-index: -1;
      }

      &.active,
      &:hover {
        &:before {
          opacity: 1;
          transform: skewX(21deg);
        }
      }

      a {
        color: #fff;
        display: block;
        padding: 13px 20px;
      }
    }
  }
}

/** ****************** Footer ****************** */
.footer {
  .payment {
    background-color: #f0f0f0;
    color: #187bba;
    font-weight: bold;
    padding: 20px 0;
    text-align: center;
  }

  .copyright {
    background-image: linear-gradient(#376afd, #0437b6);
    color: #fff;
    font-size: 0.85rem;
    padding: 20px;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .copyright-right {
      text-align: right;
      img {
        padding-top: 5px;
      }
    }
  }
}

/** ****************** Sidebar ****************** */
.right-sidebar {
  position: fixed;
  right: 20px;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  width: 120px;
  z-index: 4;

  .cs-wrapper {
    position: relative;
    margin-bottom: -50px;
    .cs-lady {
      margin-bottom: -17px;
      width: 125px;
      z-index: -1;
    }
    .cs-24hr {
      width: 100%;
    }
    .cs-text {
      color: #fff;
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: -5px;
    }
    .cs-link {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  }

  .sidebar-container {
    background: url("../img/sidebar-bg.png") top center / cover no-repeat #2d75d0;
    border: 1px solid #635ae2;
    border-radius: 6px;
    color: #fff;
    padding: 53px 10px 10px;

    .welcome {
      color: #fff653;
      font-size: 1.3rem;
      font-weight: bold;
    }

    .qr-wrapper {
      font-size: 0.9rem;
      margin: 6px 0 10px;
    }

    .sidebar-item {
      background-image: linear-gradient(#fff471, #ebc940, #c19321);
      border-radius: 20px;
      color: #331400;
      display: block;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 6px;
      padding: 5px;
      position: relative;

      &:hover {
        color: #000;
        .sidebar-desc {
          opacity: 1;
          transform: translate(0, -50%);
          visibility: visible;
        }
      }
    }

    .sidebar-desc {
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
      color: #fff;
      display: block;
      min-width: 60px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      text-decoration: none;
      position: absolute;
      right: 120px;
      top: 50%;
      transform: translate(-30%, -50%);
      padding: 5px;
      text-align: center;
      font-size: 0.8rem;
      transition: all 400ms $materialCubicBezier;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: -10px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid rgba(0, 0, 0, 0.7);
      }
      div {
        word-break: break-all;
      }

      img,
      .qr-code {
        display: block;
        max-width: 130px;
      }
    }
  }

  .sidebar-close {
    color: #2a2a2a;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 400ms $materialCubicBezier;
    &:hover {
      color: #000;
    }
  }
}

/** ****************** Marquee ****************** */
.notice-row {
  background-color: #346cfd;
  color: #fff;

  .container {
    display: flex;
    align-items: center;
  }

  .notice-desc {
    display: flex;
    align-items: center;
    padding-right: 10px;
    white-space: nowrap;
  }

  .marquee-box {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    line-height: 38px;
    height: 38px;
    ul {
      overflow: hidden;
      zoom: 1;
      margin: 0;
      li {
        margin-right: 20px;
        float: left;
        height: 40px;
        line-height: 40px;
        text-align: left;
        display: inline;
        width: auto !important;
        a {
          color: #fff;
        }
      }
    }
  }
}

/** ****************** Index ****************** */
.homepage {
  .hotgame {
    background-color: #f0f0f0;
    display: flex;
    padding: 30px 0;

    .hotgame-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .hotgame-box {
        position: relative;
        transition: all 400ms $materialCubicBezier;
        &:hover {
          transform: scale(1.1);
        }

        img {
          max-width: 100px;
        }

        a {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          &.hide {
            display: none !important;
          }
        }
      }
    }
  }

  .about {
    padding: 40px 0;

    .container {
      display: flex;
    }

    .about-col {
      color: #00369b;
      flex: 1;
      padding: 0 20px;
      position: relative;
      text-align: center;
      &:before {
        background-color: #d9d9d9;
        content: "";
        height: calc(100% - 45px); // minus title height
        width: 1px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      &:last-child {
        &:before {
          display: none;
        }
      }

      .title {
        color: #207cba;
        height: 45px;
        font-size: 1.2rem;
        font-weight: bold;
      }

      &.benefit {
        strong {
          color: #207cba;
          font-size: 1.5rem;
        }
        .flex-wrapper {
          color: #000;
          display: flex;
          align-items: flex-end;
          justify-content: space-around;
          font-size: 0.9rem;
          margin: 0 auto;
          max-width: 215px;
        }
        .progress {
          background-color: #d9d9d9;
          border-radius: 20px;
          height: 8px;
          width: 100%;
          margin: 5px auto 20px;
          max-width: 180px;
          overflow: hidden;
          .progress-bar {
            background-image: linear-gradient(to right, #48276d, #384188, #4770af);
            border-radius: 20px;
            height: 100%;
            width: 0;
          }
        }
      }

      &.aboutus {
        p {
          color: #000;
          line-height: 1.2rem;
          text-align: left;
        }
      }

      &.mobile {
        .download-qr {
          background: url("../img/about-qrbg.png") top center / cover no-repeat;
          display: inline-block;
          margin-top: 20px;
          padding: 16px 10px 23px;
        }
      }
    }
  }
}

.banner {
  position: relative;
  .swiper-container {
    height: 500px;
    overflow: hidden;

    .swiper-wrapper {
      height: 100%;
      .swiper-slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        overflow: hidden;
        .img-bg {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          filter: blur(10px) grayscale(50%);
          z-index: 0;
        }
        img {
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
        }
      }
    }

    .swiper-pagination-bullets {
      bottom: 10px;
    }
  }
}

/** ****************** Register / Edit & Forgot Pwd ****************** */
.register {
  min-height: $contentHeight;
  padding: 30px 0;

  .welcome {
    color: $colorPrimary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .step {
    background-color: #4897ed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 50px;

    .step-box {
      color: #fdec75;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:not(:last-child) {
        padding-right: 55px;
        &:after {
          content: "";
          display: inline-block;
          color: #fff;
          width: 18px;
          height: 18px;
          border-width: 1px 1px 0 0;
          border-style: solid;
          transform: rotate(45deg);
          position: absolute;
          right: 22px;
          z-index: 1;
        }
      }

      &.active {
        color: #fff;
      }

      .large {
        font-family: "Lato", sans-serif;
        font-size: 2.6rem;
        font-weight: 100;
        margin-right: 5px;
      }

      .desc {
        padding-top: 15px;
      }
    }
  }

  .register-box {
    padding: 50px;

    .form {
      .form-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .text {
          flex-basis: 10%;
          padding-right: 15px;
          text-align: right;
        }

        .inp {
          flex-basis: 35%;
          position: relative;

          input {
            color: #000;
            height: 30px;
            width: 100%;
          }

          .verify-code {
            border-radius: 4px;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        .tip {
          color: #eb4539;
          flex-basis: 55%;
          padding-left: 20px;
          text-align: left;
        }

        .btn {
          width: 100px;
        }
      }
    }
  }
}

/** ****************** Faqs ****************** */
.faq {
  min-height: $contentHeight;

  .subpage-banner {
    background: url("../img/faq-bg.png") center / cover no-repeat #4dace2;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 200px;
    .en {
      color: #3380ff;
      font-family: "Lato", sans-serif;
      font-size: 3.2rem;
    }
    .zh {
      color: #00319e;
      .large {
        font-size: 2.2rem;
        font-weight: bold;
        padding-right: 5px;
      }
    }
  }

  .faq-wrapper {
    .faq-menu {
      border-bottom: 1px solid #c8c8c8;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 30px 0 15px;

      .menu-item {
        color: #353535;
        cursor: pointer;
        text-align: center;
        transition: all 400ms $materialCubicBezier;

        &:hover {
          color: $colorPrimary;
          img {
            transform: scale(1.1);
          }
        }

        &.active {
          color: #000;
        }

        img {
          transition: all 200ms $materialCubicBezier;
          width: 80px;
        }
      }
    }

    .faq-content {
      padding: 20px;
      a {
        color: $colorPrimary;
        &:hover {
          color: #346cfd;
        }
      }
      h2 {
        color: #003d9b;
        text-align: center;
      }
      h4 {
        margin-bottom: 8px;
      }
      p {
        margin-top: 0;
        margin-bottom: 5px;
      }
    }
  }
}

/** ****************** Aff ****************** */
.aff {
  min-height: calc(100vh - 135px - 211px); // minus header, footer

  .subpage-banner {
    background: url("../img/aff-bg.png") center / cover no-repeat #4dace2;
    color: #0046a0;
    display: flex;
    align-items: center;
    height: 200px;

    .welcome {
      font-size: 1.8rem;
    }

    .large {
      color: #00439f;
      font-size: 2.2rem;
      font-weight: bold;
      padding-right: 5px;
      text-shadow: 2px 2px 3px #fff;
    }

    .en {
      color: #50affe;
      font-family: "Lato", sans-serif;
      font-size: 3.2rem;
    }

    .slogan {
      padding-left: 50px;
    }
  }

  .aff-wrapper {
    padding: 20px;

    .container {
      display: flex;
    }

    .aff-menu {
      flex-basis: 15%;
      margin-right: 4px;
      .menu-item {
        background-color: #d0d0d0;
        border: 0;
        color: #000;
        cursor: pointer;
        display: block;
        margin-bottom: 4px;
        padding: 10px;
        text-align: center;
        transition: all 400ms $materialCubicBezier;
        width: 100%;
        &:hover,
        &.active {
          background-color: #0591f8;
          color: #fff;
        }
      }
    }

    .aff-content-wrapper {
      background-color: #0591f8;
      color: #fff;
      flex-basis: 85%;
      padding: 0 20px 20px;

      h4 {
        margin-bottom: 8px;
      }
      p {
        margin-top: 5px;
      }
      ol {
        padding-left: 20px;
        margin: 0;
      }
    }
  }
}

/** ****************** App ****************** */
.app {
  background: url("../img/app-bg.png") center / cover no-repeat #a3e6ff;
  color: #fff;
  min-height: $contentHeight;
  padding: 20px 0;

  .title {
    color: $colorPrimary;
    font-size: 3.2rem;
    font-weight: bold;
    letter-spacing: 4px;
    text-align: center;
  }

  .subtitle {
    color: $colorPrimary;
    font-size: 1.6rem;
    letter-spacing: 1px;
    text-align: center;
  }

  .slogan {
    background: url("../img/app-slogan.png") center / contain no-repeat;
    font-size: 1.6rem;
    height: 80px;
    width: 500px;
    margin: 0 auto;
    padding-top: 15px;
    text-align: center;
  }

  .content {
    margin-top: 30px;

    div {
      position: relative;
    }

    img {
      max-width: 400px;
      width: 100%;
      &.logo1 {
        height: 20px;
        max-width: 70px;
        width: auto;
        position: absolute;
        right: 313px;
        top: 4px;
      }
      &.logo2 {
        height: 16px;
        max-width: 53px;
        width: auto;
        position: absolute;
        left: 340px;
        top: 85px;
      }
    }
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    > div {
      flex: 1;
      text-align: center;
    }
  }
}

/** ****************** Promo ****************** */
.promo {
  min-height: $contentHeight;

  .subpage-banner {
    background: url("../img/promo-bg.png") center / cover no-repeat #112671;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 200px;
    .en {
      font-family: "Lato", sans-serif;
      font-size: 3.5rem;
    }
    .zh {
      font-size: 1.5rem;
      text-shadow: 2px 2px 3px #1f1f1f;
      .large {
        color: #c79f30;
        font-size: 2.2rem;
        padding: 0 3px;
      }
    }
  }

  .promo-wrapper {
    padding: 20px 0;

    .promo-box {
      margin-bottom: 30px;

      .sub-content-item-content {
        background-color: #f3f3f3;
        margin-top: 8px;
        padding: 15px 20px;
        display: none;
      }

      img {
        max-width: 100%;
      }
    }
  }
}

.cooperation-list {
  display: flex;

  > div {
    text-align: left;
  }

  .cooperation-title {
    flex-basis: 150px;
    font-weight: bold;

    &:after {
      content: ":";
    }
  }

  .cooperation-qrcode {
    width: 150px;
    background: white;
  }
}
